exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commerce-install-success-js": () => import("./../../../src/pages/commerce/install_success.js" /* webpackChunkName: "component---src-pages-commerce-install-success-js" */),
  "component---src-pages-commerce-redirect-js": () => import("./../../../src/pages/commerce/redirect.js" /* webpackChunkName: "component---src-pages-commerce-redirect-js" */),
  "component---src-pages-commerce-uninstall-success-js": () => import("./../../../src/pages/commerce/uninstall_success.js" /* webpackChunkName: "component---src-pages-commerce-uninstall-success-js" */),
  "component---src-pages-gdpr-extensions-js": () => import("./../../../src/pages/gdpr/extensions.js" /* webpackChunkName: "component---src-pages-gdpr-extensions-js" */),
  "component---src-pages-goodbye-js": () => import("./../../../src/pages/goodbye.js" /* webpackChunkName: "component---src-pages-goodbye-js" */),
  "component---src-pages-ie-6-redirect-js": () => import("./../../../src/pages/ie6redirect.js" /* webpackChunkName: "component---src-pages-ie-6-redirect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onesearch-chrome-js": () => import("./../../../src/pages/onesearch/chrome.js" /* webpackChunkName: "component---src-pages-onesearch-chrome-js" */),
  "component---src-pages-onesearch-edge-js": () => import("./../../../src/pages/onesearch/edge.js" /* webpackChunkName: "component---src-pages-onesearch-edge-js" */),
  "component---src-pages-onesearch-firefox-js": () => import("./../../../src/pages/onesearch/firefox.js" /* webpackChunkName: "component---src-pages-onesearch-firefox-js" */),
  "component---src-pages-onesearch-index-js": () => import("./../../../src/pages/onesearch/index.js" /* webpackChunkName: "component---src-pages-onesearch-index-js" */)
}

